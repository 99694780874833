<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Add Rate for {{customText.text1}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="6">
                    <VueCtkDateTimePicker noHeader onlyTime input-size="sm" format="H.mm" v-model="form[0].starthour" noValueToCustomElem color="#3D348B" button-color="#191716">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[0].starthour"
                        :rules="form[0].rule"
                        label="Start Hour"
                        required
                        @keydown="isNumber($event)"
                        hide-details="auto"
                        autocomplete="off"
                        maxlength="5"
                    ></v-text-field>
                    </VueCtkDateTimePicker>
                </v-col>
                <v-col cols="12" md="6">
                    <VueCtkDateTimePicker noHeader onlyTime input-size="sm" format="H.mm" v-model="form[1].endhour" noValueToCustomElem color="#3D348B" button-color="#191716">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[1].endhour"
                        :rules="form[1].rule"
                        label="End Hour"
                        required
                        @keydown="isNumber($event)"
                        hide-details="auto"
                        autocomplete="off"
                        maxlength="5"
                    ></v-text-field>
                    </VueCtkDateTimePicker>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="form[2].rate"
                        :rules="form[2].rule"
                        label="Rate (RM)"
                        required
                        hide-details="auto"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="form[3].maxrate"
                        :rules="form[3].rule"
                        label="Max Rate (RM)"
                        required
                        hide-details="auto"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                    <v-checkbox
                    dense
                    v-model="form[5].selected"
                    :label="`Flat Rate`"
                    hide-details="auto"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" md="7">
                    <v-text-field
                        v-model.number="form[5].ratetype"
                        :rules="form[5].rule"
                        dense
                        outlined
                        required
                        label="Rate Type (Minutes)"
                        hide-details="auto"
                        :disabled="form[5].selected"
                        autocomplete="off"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-checkbox
                    dense
                    v-model="form[4].continue"
                    :label="`Continue`"
                    hide-details="auto"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="text-body-2">Subtime</p>
                    <v-btn-toggle>
                    <v-btn class="white--text" color="primary" small @click.prevent="addRow('add')" :disabled="form[5].selected">+</v-btn>
                    <v-btn class="white--text" color="error" small @click.prevent="addRow('remove')" :disabled="form[5].selected">-</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <v-row v-for="(item, index) in form[6].subtime" :key="index">
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="item.period"
                        :rules="item.rule.period"
                        label="Period"
                        required
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="form[5].selected"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="item.duration"
                        :rules="item.rule.duration"
                        label="Duration"
                        required
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="form[5].selected"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model.number="item.rate"
                        :rules="item.rule.rate"
                        label="Rate (RM)"
                        required
                        hide-details="auto"
                        autocomplete="off"
                        :disabled="form[5].selected"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary" @click="savetest"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {starthour: '', rule: [] },
                {endhour: '', rule: [] },
                {rate: '', rule: [] },
                {maxrate: '', rule: [] },
                {continue: false, rule: [] },
                {ratetypeFinal: '', ratetype: null, selected: false, rule: [] },
                {subtime: [{period: '', duration: '', rate: '', rule: {period: [], duration: [], rate: []}}]}
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.onRuleChange();
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        this.$watch(() => this.form[5].selected, this.handleTick, {deep: true});
        this.$watch(() => this.form[0].starthour, this.handleInputStartHour, {deep: true});
        this.$watch(() => this.form[1].endhour, this.handleInputEndHour, {deep: true});

        /*
        this.$watch(() => this.form[0].starthour, this.onRuleChange);
        this.$watch(() => this.form[1].endhour, this.onRuleChange);
        this.$watch(() => this.form[2].rate, this.onRuleChange);
        this.$watch(() => this.form[3].maxrate, this.onRuleChange);
        this.$watch(() => this.form[4].continue, this.onRuleChange);
        this.$watch(() => this.form[5].ratetypeFinal, this.onRuleChange);
        this.$watch(() => this.form[5].selected, this.onRuleChange);
        this.$watch(() => this.form[6].period, this.onRuleChange);
        this.$watch(() => this.form[6].duration, this.onRuleChange);
        this.$watch(() => this.form[6].rate, this.onRuleChange);
        */
    },

    methods: {
        handleTick(v){
            if (v == true) { 
                this.form[5].ratetypeFinal = 'flat'; this.form[5].ratetype = null;

                this.form[6].subtime = [{period: '', duration: '', rate: '', rule: {period: [], duration: [], rate: []}}];
            }
        },

        handleInputStartHour(newValue){
            if (newValue.length > 2) {
            newValue = newValue.replace(".", "");
            this.form[0].starthour =
                newValue.substr(0, newValue.length - 2) +
                "." +
                newValue.substr(newValue.length - 2);

            // add thousend separator formatting here
            } else {
            this.form[0].starthour = newValue;
            }
        },

        handleInputEndHour(newValue){
            if (newValue.length > 2) {
            newValue = newValue.replace(".", "");
            this.form[1].endhour =
                newValue.substr(0, newValue.length - 2) +
                "." +
                newValue.substr(newValue.length - 2);

            // add thousend separator formatting here
            } else {
            this.form[1].endhour = newValue;
            }
        },

        savetest(){
            if (!this.form[5].selected){
                let a = this.form[5].ratetype / 100;
                if (a >= 0.6){
                    a = a / 0.6;
                }

                console.log(`time_${a}`);
                this.form[5].ratetypeFinal = `time_${a}`;
            }

            if (this.form[5].selected) { this.form[5].ratetypeFinal = 'flat'; this.form[5].ratetype = null}
        },

        addRow(typ){
            let rq = {period: '', duration: '', rate: '',
                        rule: {period: [], duration: [], rate: []},
            };

            if (typ == 'add')
            this.form[6].subtime.push(rq);

            if (typ == 'remove' && this.form[6].subtime.length > 1)
            this.form[6].subtime.pop();
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
            this.form[3].rule = [];
            this.form[4].rule = [];
            this.form[5].rule = [];
        },

        async beforeCreateForm(flag){
            this.form[0].rule = [
                v => !!v || 'Start Hour is required',
            ];

            this.form[1].rule = [
                v => !!v || 'End Hour is required',
            ];

            this.form[2].rule = [
                v => !!v || 'Rate is required',
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form[3].rule = [
                v => !!v || 'Max Rate is required',
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            if (this.form[5].selected == false){
                this.form[5].rule = [
                    v => !!v || 'Rate Type is required',
                    v => !v || !isNaN(v) || 'Only number is allowed'
                ];
            }

            else{
                this.form[5].rule = []; //need this to fix bug
            }

            if (this.form[5].selected == false){
                for (var i=0; i<this.form[6].subtime.length; i++){
                    this.form[6].subtime[i].rule.period = [
                        //v => !!v || 'Period is required',
                        v => !v || !isNaN(v) || 'Only number is allowed'
                    ];

                    this.form[6].subtime[i].rule.duration = [
                        //v => !!v || 'Duration is required',
                        v => !v || !isNaN(v) || 'Only number is allowed'
                    ];

                    this.form[6].subtime[i].rule.rate = [
                        //v => !!v || 'Rate is required',
                        v => !v || !isNaN(v) || 'Only number is allowed'
                    ];
                }
            }

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                 console.log('im in');
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        async handleValue(){
            this.form[0].starthour = '';
            this.form[1].endhour = '';
            this.form[2].rate = '';
            this.form[3].maxrate = '';
            this.form[4].continue = false;
            this.form[5].ratetypeFinal = ''; this.form[5].ratetype = null; this.form[5].selected = false;
            this.form[6].subtime = [{period: '', duration: '', rate: '', rule: {period: [], duration: [], rate: []}}];
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.form[0].starthour = parseFloat(this.form[0].starthour);
                    this.form[1].endhour = parseFloat(this.form[1].endhour);
                    this.form[2].rate = parseFloat(this.form[2].rate);
                    this.form[3].maxrate = parseFloat(this.form[3].maxrate);
                    this.form[4].continue = this.form[4].continue.toString();

                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 9)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>