<template>
   <div>
      <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
         <template v-slot:item="{ item }">
            <v-breadcrumbs-item
               href="#"
               :disabled="item.disabled"
               >
               {{ item.text }}
            </v-breadcrumbs-item>
         </template>
      </v-breadcrumbs>
      <v-card class="mx-auto" outlined tile color="#F8F8FF">
         <v-overlay
            :absolute="absolute"
            :value="overlay"
            >
            <v-progress-circular
               indeterminate
               color="primary"
               ></v-progress-circular>
         </v-overlay>
         <v-card-title>Create Merchant Rewards</v-card-title>
         <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
            <v-container class="fill-height"
               fluid>
               <v-row>
                  <v-col cols="12" md="12">
                     <v-autocomplete
                        dense
                        outlined
                        label="Corporate Account *"
                        v-model="reward_details.corporate.selected"
                        :items="reward_details.corporate.items"
                        item-text="name"
                        item-value="loginEmailID"
                        :rules="reward_details.corporate.rule"
                        :loading="reward_details.corporate.loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-autocomplete
                        dense
                        outlined
                        label="Operator"
                        v-model="form[24].selected"
                        @change="execFilter('operator')"
                        :items="form[24].operator"
                        item-text="name"
                        item-value="OperatorID"
                        :loading="form[24].loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-autocomplete
                        dense
                        outlined
                        label="Car Park"
                        v-model="form[0].selected"
                        @change="execFilter('carpark')"
                        :items="form[0].carpark"
                        item-text="name"
                        item-value="CarParkID"
                        :loading="form[0].loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                     <v-autocomplete
                        dense
                        outlined
                        label="Reward Slot Name *"
                        v-model="reward_details.reward_name.selected"
                        @change="execFilter('name')"
                        :items="reward_details.reward_name.items"
                        item-text="GroupName"
                        item-value="SlotGroupID"
                        :rules="reward_details.reward_name.rule"
                        :loading="reward_details.reward_name.loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <!--
                  <v-col cols="12" md="12">
                     <span class="text-body-2">Duration: {{reward_details.duration.item}} {{reward_details.slot_type.item}}</span>
                  </v-col>
                  -->
                  <v-col cols="12">
                    <v-row dense no-gutters>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                v-model="reward_details.duration.item"
                                label="Slot Duration"
                                hide-details="auto"
                                disabled
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                dense
                                outlined
                                :value="reward_details.slot_type.item.charAt(0).toUpperCase() + reward_details.slot_type.item.toLowerCase().slice(1) + s"
                                label="Slot Type"
                                hide-details="auto"
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                  <!--
                  <v-col cols="12" sm="12" md="6">
                     <vc-date-picker v-if="componentloader" :disabled-dates="{start: null, end: allowedstartdate}" v-model="reward_details.start.item" @input="calculateDuration()" update-on-input popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                        <template v-slot="{ inputValue, inputEvents, togglePopover }">
                           <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                 <div
                                    v-bind:class="[ validateStartDateMain ]"
                                    class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                    <div class="v-input__control">
                                       <div class="v-input__slot white">
                                          <fieldset aria-hidden="true">
                                             <legend :style="focused || reward_details.start.item ? {'width': '100px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                          </fieldset>
                                          <div class="v-text-field__slot">
                                             <label for="input-startdate" v-bind:class="[ validateStartDate ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Rewards Start Date *</label>
                                             <input
                                             id="input-startdate"
                                             @focus="focused = true"
                                             @blur="focused = false"
                                             :value="inputValue"
                                             v-on="inputEvents"
                                             @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                             autocomplete="off"
                                             />
                                          </div>
                                       </div>
                                       <div class="v-text-field__details">
                                          <v-input
                                             error
                                             readonly
                                             v-model="reward_details.start.item"
                                             :rules="reward_details.start.rule"
                                             height="0"
                                             class="hide-input-text"
                                             >
                                          </v-input>
                                       </div>
                                    </div>
                                 </div>
                              </v-col>
                           </v-row>
                        </template>
                     </vc-date-picker>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                     <vc-date-picker v-if="componentloader" v-model="reward_details.end.item" update-on-input popover mode="date" :masks="{ input: 'DD/MM/YYYY' }">
                        <template v-slot="{ inputValue, inputEvents, togglePopover }">
                           <v-row dense>
                              <v-col cols="12" sm="12" md="12">
                                 <div
                                    v-bind:class="[ validateEndDateMain, true ? 'v-input--is-disabled' : '' ]"
                                    class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                                    <div class="v-input__control">
                                       <div class="v-input__slot white">
                                          <fieldset aria-hidden="true">
                                             <legend :style="focusedend || reward_details.end.item ? {'width': '100px'} : {'width': '0px'}"><span>&#8203;</span></legend>
                                          </fieldset>
                                          <div class="v-text-field__slot">
                                             <label for="input-enddate" v-bind:class="[ validateEndDate, true ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">Rewards End Date</label>
                                             <input
                                             id="input-enddate"
                                             @focus="focusedend = true"
                                             @blur="focusedend = false"
                                             :value="inputValue"
                                             v-on="inputEvents"
                                             @click="togglePopover({ placement: 'top-start', visibility: 'click' })"
                                             autocomplete="off"
                                             :disabled="true"
                                             />
                                          </div>
                                       </div>
                                       <div class="v-text-field__details">
                                          <v-input
                                             error
                                             readonly
                                             v-model="reward_details.end.item"
                                             :rules="reward_details.end.rule"
                                             height="0"
                                             class="hide-input-text"
                                             >
                                          </v-input>
                                       </div>
                                    </div>
                                 </div>
                              </v-col>
                           </v-row>
                        </template>
                     </vc-date-picker>
                  </v-col>
                  -->
                  <v-col cols="12" sm="12" md="12">
                     <span class="text-body-2">Promo Voucher</span>
                     <v-switch
                        color="grey"
                        class="col-3 pl-0"
                        v-model="reward_details.promo_voucher.item"
                        :label="`${reward_details.promo_voucher.item ? 'Yes' : 'No'}`"
                        disabled
                        hide-details="auto"
                        :hint="reward_details.promo_voucher.item ? 'Promo Voucher feature is enable for this Reward Slot.' : 'Promo Voucher feature is disable for this Reward Slot.'"
                        persistent-hint
                        ></v-switch>
                  </v-col>
                  <v-col cols="12" md="12" v-if="reward_details.promo_voucher.item == 1">
                     <v-text-field
                        dense
                        outlined
                        type="text"
                        v-model="reward_details.total_qty.item"
                        label="Voucher Quantity"
                        required
                        background-color="white"
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                     <span class="text-body-2">Quantity Info</span>
                     <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="reward_details.purchase_items"
                        hide-default-footer
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        disable-sort
                        hide-default-header
                        no-data-text="Empty"
                        >
                        <template slot="item" slot-scope="props">
                           <tr style="height: 85px">
                              <td>
                                 <v-btn-toggle style="width: 100%; background: transparent">
                                    <v-btn class="white--text" color="error" small @click.prevent="addRow('remove')" :disabled="disablepurchaseqty">-</v-btn>
                                    <v-text-field
                                       dense
                                       outlined
                                       type="text"
                                       v-model.number="props.item.purchaseqty.item"
                                       :rules="props.item.purchaseqty.rule"
                                       label="Purchase Quantity *"
                                       required
                                       @keyup.prevent="calcTotalAmount()"
                                       background-color="white"
                                       :readonly="disablepurchaseqty"
                                       autocomplete="off"
                                       ></v-text-field>
                                    <v-btn class="white--text" color="primary" small @click.prevent="addRow('add')" :disabled="disablepurchaseqty">+</v-btn>
                                    <v-btn class="white--text" color="blue" small @click.prevent="addRow('max')" :disabled="disablepurchaseqty">Max</v-btn>
                                 </v-btn-toggle>
                              </td>
                              <td>
                                 <v-text-field
                                    dense
                                    outlined
                                    type="text"
                                    v-model.number="props.item.grandfinaltotal.item"
                                    label="Slot Unit Price (RM)"
                                    required
                                    background-color="white"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </td>
                              <td>
                                 <v-text-field
                                    dense
                                    outlined
                                    type="text"
                                    v-model.number="props.item.totalamount.item"
                                    label="Total Amount (RM)"
                                    required
                                    background-color="white"
                                    autocomplete="off"
                                    disabled
                                    ></v-text-field>
                              </td>
                           </tr>
                        </template>
                     </v-data-table>
                  </v-col>
                  <v-col cols="12" md="12">
                      <v-autocomplete
                                    dense
                                    outlined
                                    label="Payment Method *"
                                    v-model="reward_details.purchase_items[0].paymentmethod.selected"
                                    :items="reward_details.purchase_items[0].paymentmethod.items"
                                    item-text="name"
                                    item-value="id"
                                    :rules="reward_details.purchase_items[0].paymentmethod.rule"
                                    background-color="white"
                                    hide-details="auto"
                                    >
                                 </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" v-if="reward_details.purchase_items[0].paymentmethod.selected == 'GIRO'">
                      <v-text-field
                                       dense
                                       outlined
                                       type="text"
                                       v-model.number="reward_details.purchase_items[0].reference.item"
                                       :rules="reward_details.purchase_items[0].reference.rule"
                                       label="Reference *"
                                       required
                                       background-color="white"
                                       autocomplete="off"
                                       hide-details="auto"
                                       ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" v-if="reward_details.purchase_items[0].paymentmethod.selected == 'GIRO'">
                      <dpicker :datetime="reward_details.purchase_items[0].refdate" :mode="'datetime'" @date="dateValRefTime" :close="dialog_local" :place="true" label="Transaction Date *" :width="'80px'"></dpicker>
                  </v-col>
                  <v-card-actions>
                     <v-btn v-if="!readonly" class="mt-4" tile small color="primary" type="submit" :loading="btn_loading">
                        <v-icon left x-small>fa fa-credit-card</v-icon>
                        Proceed to Payment
                     </v-btn>
                     <v-btn class="mt-4" tile small color="error" type="button" :to="{ name: 'Merchant Rewards' }">
                        <v-icon left x-small>fa fa-ban</v-icon>
                        Cancel
                     </v-btn>
                  </v-card-actions>
               </v-row>
            </v-container>
         </v-form>
      </v-card>
      <v-snackbar
         v-model="swal.notification"
         top
         :color="swal.scolor"
         >
         {{ swal.message }}
      </v-snackbar>
      <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
      <edit :dialog="ma.modal_edit" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
      <alertbox :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
      <alertredirect :dialog="ma.alertredirect" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertredirect>
      <notification :dialog="ex.modal" @closed="onClosed" :content="ex.content"></notification>
   </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import create from '.././modals/AddRate';
import edit from '.././modals/ChangeRewardSlotStatus';
import tpicker from "../TimePickerComponent";
import alertbox from '../modals/Alert';
import alertredirect from '../modals/AlertRedirect';
import TextEditor from '../TextEditor';
import * as moment from "moment";
import * as tz from "moment-timezone";
import * as AppStorage from '../../assets/js/storage';
import {rule} from '../../assets/js/checkPerm';
import dpicker from "../DatePickerComponent3";
import QrcodeVue from 'qrcode.vue'
import notification from '../modals/Notification';

export default {
    name: 'Create Merchant Rewards',
    title: 'Create Merchant Rewards',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Merchant Reward',
                disabled: true,
                href: '#',
                },

                {
                text: 'Merchant Rewards',
                disabled: true,
                href: '#',
                },

                {
                text: 'Create Merchant Rewards',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            cutoffval: '',
            cutoffrule: [],
            graceval: '',
            gracerule: [],
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            search: '',
            headers: [
                { text: '', value: '1', width: '15%' },
                { text: '', value: 'r1', width: '12%' },
                { text: '', value: 'r2', width: '12%' },
                { text: '', value: 'r3', width: '12%' },
            ],
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                alertmodal: false,
                alertredirect: false,

                modal_edit: false,
            },
            form: [ 
                {carpark: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false, }, //0
                {vpname: '', rule: [] }, //1
                {vpdesc: '', rule: [] }, //2
                {tnc: '', rule: [] }, //3
                {file: '', imgrule: [], image: '', btn_loading: '', logo: '' }, //4
                {purchasecode: false, rule: [], code: '' }, //5
                {qty: null, rule: [] }, //6
                {minpurchaseqty: null, rule: [] }, //7
                {maxpurchaseqty: null, rule: [] }, //8
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //9 sell date
                {range: {start: '', end: ''}, rulestart: [], ruleend: [] }, //10 use date
                {vpcost: '', rule: [] }, //11
                {vptype: [{id: '', name: 'Select'}, {id: 'time', name: 'Time'}, {id: 'duration', name: 'Duration'}], rule: [], selected: '' }, //12
                {multientry: false, rule: [] }, //13
                {numofentry: '', rule: [] }, //14
                {allowedparkingdurationhours: '', rule: [] }, //15
                {entrytimestart: '', rule: [] }, //16
                {entrytimeend: '', rule: [] }, //17
                {exittimestart: '', rule: [] }, //18
                {exittimeend: '', rule: [] }, //19
                {alloweddays: [
                    {id: 'Monday', name: 'Monday'}, 
                    {id: 'Tuesday', name: 'Tuesday'}, 
                    {id: 'Wednesday', name: 'Wednesday'},
                    {id: 'Thursday', name: 'Thursday'},
                    {id: 'Friday', name: 'Friday'},
                    {id: 'Saturday', name: 'Saturday'},
                    {id: 'Sunday', name: 'Sunday'},
                    ],
                    rule: [], selected: []
                }, //20
                {displaystatus: false, rule: [] }, //21
                {allowedentrydates: [], rule: [] }, //22
                {numofparkingdays: null, rule: [] }, //23
                {operator: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false, }, //24
                {addonqty: '', rule: [] }, //25
                {active: false }, //26
            ],
            reward_details: {
                merchant_name: {item: '', rule: []},
                mobile_display: {item: false},
                image1: {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                image2: {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                url: {item: '', rule: []},
                duration: {item: ''},
                slot_type: {item: ''},
                status: {item: ''},
                description: {item: '', rule: []},
                tnc: {item: '', rule: []},
                start: {item: '', rule: []},
                end: {item: '', rule: []},
                promo_start: {item: '', rule: []},
                promo_end: {item: '', rule: []},
                promo_voucher: {item: false},
                total_qty: {item: '', rule: []},
                remain_qty: {item: ''},
                reason: '',
                corporate: {items: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false},
                reward_name: {items: [], itemsFull: [], rule: [], selected: '', loader: false, disable: false},
                purchase_items: [{
                    purchaseqty: {item: '', rule: []},
                    grandfinaltotal: {item: '', rule: []},
                    totalamount: {item: '', rule: []},
                    paymentmethod: {items: [
                        {id: '', name: 'Select'},
                        {id: 'FPXDIRECTWEB', name: 'Online'},
                        {id: 'GIRO', name: 'Giro'},
                        {id: "CASH", name: 'Cash'}
                    ], rule: [], selected: ''},
                    reference: {item: '', rule: []},
                    refdate: {item: '', rule: []},
                }],
            },
            componentloader: true,
            role_selected: [],
            perm_list: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false,
            firstloadeditems: [],
            readonly: false,
            allowedstartdate: new Date(moment(new Date()).subtract('1', 'days')),
            focused: false,
            focusedend: false,
            focusedpromostart: false,
            focusedpromoend: false,
            qr: {
                items: [],
                qrcode: ''
            },
            disablepurchaseqty: false,
            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },
            s: ''
        }
    },

    components: {create, edit, alertbox, 'text-editor': TextEditor, tpicker, dpicker, QrcodeVue, alertredirect, notification},

    async created(){
        this.$vuetify.goTo(0);
        this.getCorporateFull();
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.getMerchantRewardSlotAll();
        let passref = this.$route.query.passref == undefined ? "" : this.$route.query.passref;
        let refno = this.$route.query.refno == undefined ? "" : this.$route.query.refno;
        if (passref !== ""){
            await this.checkiPayTrxnStatus('FPXDIRECTWEB', refno);
        }
    },

    computed: {
        validateStartDate(){
            let classes = '';
            if (this.focused || this.reward_details.start.item){
                classes += 'primary--text v-label--active';
            }

            if (this.reward_details.start.item == '' && this.reward_details.start.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focused){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.reward_details.start.item == '' && this.reward_details.start.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        validateEndDate(){
            let classes = '';
            if (this.focusedend || this.reward_details.end.item){
                classes += 'primary--text v-label--active';
            }

            if (this.reward_details.end.item == '' && this.reward_details.end.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateEndDateMain(){
            let classes = '';
            if (this.focusedend){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.reward_details.end.item == '' && this.reward_details.end.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },
    },

    methods: {
        async getMerchantRewardSlotAll(v){
            this.reward_details.reward_name.items = [];
            this.reward_details.reward_name.selected = "";

            try{
                this.reward_details.reward_name.loader = true;
                this.reward_details.reward_name.disable = true;

                let response = await this.$store.dispatch("getMerchantRewardSlotGroupAll", {search: ''});
                let dt = response.data.record;

                if (v == 'operator'){
                    this.reward_details.reward_name.items.push({GroupName: "Select", SlotGroupID: ""});
                    if (this.form[24].selected !== ""){
                        let itms = this.reward_details.reward_name.itemsFull.filter(i => i.OperatorID == this.form[24].selected);
                        for(var i=0; i<itms.length; i++){
                            this.reward_details.reward_name.items.push({GroupName: itms[i].GroupName, SlotGroupID: itms[i].SlotGroupID});
                        }
                    }

                    else{
                        for(var i=0; i<dt.length; i++){
                            this.reward_details.reward_name.items.push({GroupName: dt[i].GroupName, SlotGroupID: dt[i].SlotGroupID});
                            this.reward_details.reward_name.itemsFull.push(dt[i]);
                        }

                        this.reward_details.reward_name.selected = "";
                    }
                }

                else if (v == 'carpark'){
                    this.reward_details.reward_name.items.push({GroupName: "Select", SlotGroupID: ""});
                    if (this.form[0].selected !== ""){
                        let itms = this.reward_details.reward_name.itemsFull.filter(i => i.CarParkID == this.form[0].selected);
                        for(var i=0; i<itms.length; i++){
                            this.reward_details.reward_name.items.push({GroupName: itms[i].GroupName, SlotGroupID: itms[i].SlotGroupID});
                        }
                    }

                    else{
                        for(var i=0; i<dt.length; i++){
                            this.reward_details.reward_name.items.push({GroupName: dt[i].GroupName, SlotGroupID: dt[i].SlotGroupID});
                            this.reward_details.reward_name.itemsFull.push(dt[i]);
                        }

                        this.reward_details.reward_name.selected = "";
                    }
                }

                else{
                    if (response.data.code == 'AP000'){
                        this.reward_details.reward_name.items.push({GroupName: "Select", SlotGroupID: ""});
                        this.reward_details.reward_name.itemsFull.push({GroupName: "Select", SlotGroupID: ""});

                    for(var i=0; i<dt.length; i++){
                        this.reward_details.reward_name.items.push({GroupName: dt[i].GroupName, SlotGroupID: dt[i].SlotGroupID});
                        this.reward_details.reward_name.itemsFull.push(dt[i]);
                    }

                    this.reward_details.reward_name.selected = "";
                    }
                }

                //this.operator.itemsFull = dt;

                this.reward_details.reward_name.loader = false;
                this.reward_details.reward_name.disable = false;
            }

            catch(err){
                console.log(err);
            }
        },

        async checkiPayTrxnStatus(gateway, ref){
            try{
                let response = await this.$store.dispatch("checkiPayTrxnStatus", {gateway: gateway, ref: ref});
                let dt = response.data.record;
                let items = AppStorage.get('rewardsetuptemp');
                if (response.data.code == 'AP000'){
                    if (response.data.finaltransactionstatus == 1){
                        this.handleTempItems(items);
                        this.ex.modal = true;
                        this.ex.content.headline = 'Transaction Completed!';
                        this.ex.content.icon = 'fa fa-check';
                        this.ex.content.chipColor = '#a0e8af';
                        this.ex.content.iconColor = 'green';
                        this.ex.content.maintext = 'You have completed the payment process';
                        this.ex.content.smalltext = 'You may close this message';
                    }

                    if (response.data.finaltransactionstatus == 2){
                        this.ex.modal = true;
                        this.ex.content.headline = 'Transaction Failed!';
                        this.ex.content.icon = 'fa fa-times-circle';
                        this.ex.content.chipColor = 'white';
                        this.ex.content.iconColor = 'red';
                        this.ex.content.maintext = 'The payment process has been cancelled.';
                        this.ex.content.smalltext = 'You may close this message';

                        this.handleTempItems(items);
                    }

                    if (response.data.finaltransactionstatus == 0){
                        this.ex.modal = true;
                        this.ex.content.headline = 'Transaction Pending!';
                        this.ex.content.icon = 'fa fa-times-circle';
                        this.ex.content.chipColor = 'white';
                        this.ex.content.iconColor = 'red';
                        this.ex.content.maintext = 'The payment process has been cancelled.';
                        this.ex.content.smalltext = 'You may close this message';

                        this.handleTempItems(items);
                    }
                }

                else {
                    this.ex.modal = true;
                    this.ex.content.headline = 'Transaction Failed!';
                    this.ex.content.icon = 'fa fa-times-circle';
                    this.ex.content.chipColor = 'white';
                    this.ex.content.iconColor = 'red';
                    this.ex.content.maintext = 'The payment process has been cancelled.';
                    this.ex.content.smalltext = 'You may close this message';

                    this.handleTempItems(items);
                }

                AppStorage.remove("rewardsetuptemp");
                this.$router.replace({'query': null});
            }

            catch(err){
                console.log(err);
            }
        },

        async getCorporateFull(){
            this.reward_details.corporate.items = [];
            this.reward_details.corporate.selected = "";

            try{
                this.reward_details.corporate.loader = true;
                this.reward_details.corporate.disable = true;

                let response = await this.$store.dispatch("getSeasonSubscriberCorporateAll", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.reward_details.corporate.items.push({name: "Select", loginEmailID: ""});
                    this.reward_details.corporate.itemsFull.push({name: "Select", loginEmailID: ""});

                for(var i=0; i<dt.length; i++){
                    dt[i].name = `${dt[i].loginEmailID} - ${dt[i].CorporateName}`;
                    this.reward_details.corporate.items.push({name: dt[i].name, loginEmailID: dt[i].loginEmailID});
                    this.reward_details.corporate.itemsFull.push(dt[i]);
                }

                this.reward_details.corporate.selected = "";
                }

                //this.operator.itemsFull = dt;

                this.reward_details.corporate.loader = false;
                this.reward_details.corporate.disable = false;
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.form[24].operator = [];
            this.form[24].selected = "";

            try{
                this.form[24].loader = true;
                this.form[24].disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.form[24].operator.push({name: "Select", OperatorID: ""});
                    this.form[24].itemsFull.push({name: "Select", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.form[24].operator.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.form[24].itemsFull.push(dt[i]);
                    }
                }

                this.form[24].selected = this.form[24].operator[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.form[24].loader = false;
                this.form[24].disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.form[0].carpark = [];
            this.form[0].selected = "";

            try{
                this.form[0].loader = true;
                this.form[0].disable = true;

                let self = this;

                if (this.form[24].selected){
                    let carpark = this.form[0].itemsFull.filter(item => {
                    return item.OperatorID == self.form[24].selected;
                    });

                    this.form[0].carpark.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.form[0].carpark.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.form[0].carpark.push({name: "Select", CarParkID: ""});
                        this.form[0].itemsFull.push({name: "Select", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.form[0].carpark.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.form[0].itemsFull.push(dt[i]);
                        }
                    }

                    this.form[0].selected = this.form[0].carpark[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.form[0].loader = false;
                this.form[0].disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async createMerchantRewards(v){
            this.btn_loading = true;
            this.tableloading = true;
            let paymenttrxdatetime = moment(new Date(this.reward_details.purchase_items[0].refdate.item)).subtract(8, 'hours').format('YYYY-MM-DD HH:mm:ss');
            let paymentgateway = this.reward_details.purchase_items[0].paymentmethod.selected;
            try{
                this.swal.scolor = 'green';
                let req = {
                    loginemailid: this.reward_details.corporate.selected,
                    slotgroupid: this.reward_details.reward_name.selected,
                    purchasequantity: this.reward_details.purchase_items[0].purchaseqty.item,
                    paymentgateway: paymentgateway,
                    paymenttrxid: paymentgateway == "GIRO" ? this.reward_details.purchase_items[0].reference.item : null,
                    paymenttrxdatetime: paymentgateway == "GIRO" ? paymenttrxdatetime : null
                };
                let response = await this.$store.dispatch("createMerchantRewardSlot", req);
                if (response.data.code === 'AP000'){
                    if (paymentgateway == "FPXDIRECTWEB"){
                        AppStorage.set("rewardsetuptemp", JSON.stringify(req));
                        AppStorage.set("operatortemp", this.form[24].selected);
                        AppStorage.set("carparktemp", this.form[0].selected);
                        window.location.replace(response.data.actionpaymenturl);
                    }

                    else
                    this.$router.push({ name: 'Merchant Rewards', query: {created: true, id: this.reward_details.corporate.selected, slotgroupid: this.reward_details.reward_name.selected, operatorid: this.form[24].selected, carparkid: this.form[0].selected}});
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = "Something went wrong. Please try again later";
            }
        },

        onClosed(value){
            console.log(value);
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_edit = value;
                this.ex.modal = value;
                let paymentgateway = this.reward_details.purchase_items[0].paymentmethod.selected;
                if (paymentgateway == "FPXDIRECTWEB")
                this.ma.alertredirect = value;

                else
                this.ma.alertmodal = value;

                if (this.ex.content.headline == 'Transaction Completed!'){
                    this.$router.push({ name: 'Merchant Rewards', query: {created: true, id: this.reward_details.corporate.selected, slotgroupid: this.reward_details.reward_name.selected, operatorid: this.form[24].selected, carparkid: this.form[0].selected}});
                }
            }
        },

        onConfirm(value){
            console.log(value);
            if (value == true){
                this.ma.confirmCreate = true;
                let paymentgateway = this.reward_details.purchase_items[0].paymentmethod.selected;
                if (paymentgateway == "FPXDIRECTWEB")
                this.ma.alertredirect = false;

                else
                this.ma.alertmodal = false;

                this.createMerchantRewards();
            }
        },

        onRuleChange() {
            this.reward_details.corporate.rule = [];
            this.reward_details.reward_name.rule = [];
            this.reward_details.purchase_items[0].purchaseqty.rule = [];
            this.reward_details.purchase_items[0].paymentmethod.rule = [];
            this.reward_details.start.rule = [];
            this.reward_details.purchase_items[0].reference.rule = [];
            this.reward_details.purchase_items[0].refdate.rule = [];
        },

        async beforeCreateForm(flag){
            this.onRuleChange();
            
            let qtymax = false;
            let slotsleft = false;

            if (this.reward_details.reward_name.selected !== ""){
                let itms = this.reward_details.reward_name.itemsFull.find(i => i.SlotGroupID == this.reward_details.reward_name.selected);
                qtymax = this.reward_details.purchase_items[0].purchaseqty.item > itms.SlotsLeft;
                slotsleft = itms.SlotsLeft == 0 ? true : false;

                if (slotsleft){
                    this.reward_details.purchase_items[0].purchaseqty.rule = [
                        v => 'Merchant Reward Slot has been sold out.'
                    ];
                }

                else{
                    this.reward_details.purchase_items[0].purchaseqty.rule = [
                        v => !!v || 'This is required',
                        v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
                        v => !qtymax || 'Value chosen is higher than available slots. Please reduce it.',
                    ];
                }
            }

            this.reward_details.corporate.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.reward_name.rule = [
                    v => !!v || 'This is required',
            ];

            this.reward_details.purchase_items[0].paymentmethod.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.start.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.purchase_items[0].reference.rule = [
                v => !!v || 'This is required',
            ];

            this.reward_details.purchase_items[0].refdate.rule = [
                v => !!v || 'This is required',
            ];

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                let paymentgateway = self.reward_details.purchase_items[0].paymentmethod.selected;
                if (paymentgateway == "FPXDIRECTWEB")
                self.ma.alertredirect = true;

                else
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode != 9)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
                await this.getMerchantRewardSlotAll(type);
            }

            if (type == 'carpark'){
                await this.getMerchantRewardSlotAll(type);
            }

            if (type == 'name'){
                this.onRuleChange();
                this.disablepurchaseqty = false;
                let itms = this.reward_details.reward_name.itemsFull.find(i => i.SlotGroupID == this.reward_details.reward_name.selected);
                this.reward_details.slot_type.item = itms.PurchaseDurationUnit;
                this.reward_details.duration.item = itms.PurchaseDurationPerSlot;
                this.reward_details.promo_voucher.item = itms.HavePromoVoucher;
                this.reward_details.purchase_items[0].grandfinaltotal.item = itms.GrandFinalTotal;
                this.reward_details.total_qty.item = itms.PromoVoucherQuantity;
                if (itms.SlotsLeft > 0)
                this.reward_details.purchase_items[0].purchaseqty.item = 1;
                else if (itms.SlotsLeft == 0){
                    this.reward_details.purchase_items[0].purchaseqty.item = 0;
                    this.disablepurchaseqty = true;
                }

                if (this.reward_details.reward_name.selected == ""){
                    this.reward_details.start.item = "";
                    this.reward_details.end.item = "";
                }
                else{
                    this.calcTotalAmount();
                    if (this.reward_details.start.item !== "") this.calculateDuration();
                }

                if (this.reward_details.purchase_items[0].totalamount.item == 0){
                    this.reward_details.purchase_items[0].paymentmethod.selected = "";
                    this.reward_details.purchase_items[0].paymentmethod.items = [
                        {id: '', name: 'Select'},
                        {id: "CASH", name: 'Cash'}
                    ];
                }

                else{
                    this.reward_details.purchase_items[0].paymentmethod.selected = "";
                    this.reward_details.purchase_items[0].paymentmethod.items = [
                        {id: '', name: 'Select'},
                        {id: 'FPXDIRECTWEB', name: 'Online'},
                        {id: 'GIRO', name: 'Giro'},
                        {id: "CASH", name: 'Cash'}
                    ];
                }

                if (this.reward_details.slot_type.item.length > 0){
                    this.s = "(s)";
                }

                else{
                    this.s = "";
                }

                if (this.reward_details.reward_name.selected !== ""){
                    let qtymax = false;
                    let slotsleft = false;

                    let itms = this.reward_details.reward_name.itemsFull.find(i => i.SlotGroupID == this.reward_details.reward_name.selected);
                    qtymax = this.reward_details.purchase_items[0].purchaseqty.item > itms.SlotsLeft;
                    slotsleft = itms.SlotsLeft == 0 ? true : false;

                    if (slotsleft){
                        this.reward_details.purchase_items[0].purchaseqty.rule = [
                            v => 'Merchant Reward Slot has been sold out.'
                        ];
                    }

                    else{
                        this.reward_details.purchase_items[0].purchaseqty.rule = [
                            v => !!v || 'This is required',
                            v => Number.isInteger(Number(v)) || 'Only whole number is allowed',
                            v => !qtymax || 'Value chosen is higher than available slots. Please reduce it.',
                        ];
                    }

                    let self = this;
                    
                    self.$refs.ct.validate();
                }
            }
        },

        calculateDuration(){
            let startselldate = moment(new Date(new Date(this.reward_details.start.item))).utc().format('YYYY-MM-DD');
            let endselldate = moment(new Date(new Date(this.reward_details.end.item))).utc().format('YYYY-MM-DD');

            let slot_type = "";
            let days = 0;
            if (this.reward_details.slot_type.item == "month") slot_type = 31;
            if (this.reward_details.slot_type.item == "week") slot_type = 7;
            if (this.reward_details.slot_type.item == "year") slot_type = 365;

            days = this.reward_details.duration.item * slot_type;
            console.log('days', days);

            this.reward_details.end.item = new Date(moment(this.reward_details.start.item).add(days, 'days'));
        },

        calcTotalAmount(){
            let total = 0;

            total = this.reward_details.purchase_items[0].purchaseqty.item * this.reward_details.purchase_items[0].grandfinaltotal.item;

            this.reward_details.purchase_items[0].totalamount.item = total.toFixed(2);
        },

        addRow(typ){
            let itms = this.reward_details.reward_name.itemsFull.find(i => i.SlotGroupID == this.reward_details.reward_name.selected);
            
            if (typ == 'add'){
                this.reward_details.purchase_items[0].purchaseqty.item++;
                if (this.reward_details.purchase_items[0].purchaseqty.item > itms.SlotsLeft)
                this.reward_details.purchase_items[0].purchaseqty.item = itms.SlotsLeft;
            }

            if (typ == 'remove'){
                this.reward_details.purchase_items[0].purchaseqty.item--;
                if (this.reward_details.purchase_items[0].purchaseqty.item < 1)
                this.reward_details.purchase_items[0].purchaseqty.item = 1;
            }

            if (typ == 'max'){
                this.reward_details.purchase_items[0].purchaseqty.item = itms.SlotsLeft;
            }

            this.calcTotalAmount();
        },

        dateValRefTime(value){
            this.reward_details.purchase_items[0].refdate.item = value;
        },

        handleTempItems(items){
            let temp_items = JSON.parse(items);
            this.reward_details.corporate.selected = temp_items.loginemailid;
            this.reward_details.reward_name.selected = temp_items.slotgroupid;
            this.reward_details.purchase_items[0].paymentmethod.selected = "FPXDIRECTWEB";
            this.execFilter("name");
            this.reward_details.purchase_items[0].purchaseqty.item = temp_items.purchasequantity;
            this.calcTotalAmount();
            let operator = AppStorage.get('operatortemp');
            let carpark = AppStorage.get('carparktemp');
            this.form[24].selected = operator == "" ? "" : Number(operator);
            this.form[0].selected = carpark == "" ? "" : Number(carpark);
            AppStorage.remove('operatortemp');
            AppStorage.remove('carparktemp');
        }
    }
}
</script>