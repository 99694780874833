<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="400">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('edit')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Change Status for Slot ID {{customText.text1}}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-autocomplete background-color="white"
                        dense
                        v-model="form.reason.selected"
                        :items="form.reason.items"
                        :rules="form.reason.rule"
                        :loading="form.reason.loading"
                        item-text="description"
                        item-value="reviewid"
                        label="Reason *"
                        hide-details="auto"
                        outlined
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import * as moment from "moment";

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: {
                reason: {items: [], selected: '', rule: [], loading: false
                },
            },

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        
    },

    methods: {
        async listAll(){
            try{
                this.form.reason.loading = true;
                let response = await this.$store.dispatch("getReviewItems", {});

                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let items = [];

                items.push({reviewid: "", description: "Select"})

                dt.forEach((doc, i) => { 
                    items.push({reviewid: doc.reviewid, description: doc.description});
                })

                this.form.reason.items = items;
                this.form.reason.loading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        clearRule(){
            this.form.reason.rule = [];
        },

        async beforeCreateForm(flag){
            this.clearRule();

            this.form.reason.rule = [
                v => !!v || 'Please select an option'
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
                this.componentloader = false;
                this.clearRule();
            }
        },

        async handleValue(){
            this.form.reason.selected = '';
            this.form.reason.items = [];
            await this.listAll();
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
                if (this.ma.modal_action_type == 'edit'){
                    let req = {
                        reason: this.form.reason.selected,
                    };

                    req.flag = 'edit';
                    this.$emit('confirm', req);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>