<template>
    <div v-bind:class="validateCutoffMain"
        class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
        <div class="v-input__control">
            <div class="v-input__slot white">
                <fieldset aria-hidden="true"><legend :style="focuscutoff || form.cutofftime.item ? {'width': width} : {'width': '0px'}"><span>&#8203;</span></legend></fieldset>
                <div class="v-text-field__slot">
                <label for="input-starthour" v-bind:class="validateCutoff" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">{{label}}</label>
                <vue-timepicker placeholder=" " style="padding: none" id="input-starthour" input-class="vtimepicker" @focus="focuscutoff = true"
                                @blur="focuscutoff = false" v-model="form.cutofftime.item" format="hh:mm a" manual-input :disabled="readonlylocal">
                    <template v-slot:clearButton>
                        <v-icon x-small>fa fa-times</v-icon>
                    </template>
                </vue-timepicker>
                </div>
            </div>
            <div class="v-text-field__details" v-if="form.cutofftime.item == '' && form.cutofftime.rule.length > 0">
                <v-input
                    error
                    readonly
                    v-model="form.cutofftime.item"
                    :rules="form.cutofftime.rule"
                    height="0"
                    class="hide-input-text"
                    hide-details="auto"
                >
                </v-input>
            </div>
        </div>
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as moment from "moment";

export default {
    props: ['datetime', 'rules', 'label', 'close', 'useforedit', 'width', 'mode', 'readonly'],

    data() {
        return {
            form: {
                cutofftime: {item: "", formatted: "", rule: []},
            },
            focuscutoff: false,
            readonlylocal: ''
        }
    },

    components: {VueTimepicker},

    watch: { 
      	'form.cutofftime.item': function(newVal, oldVal) { // watch it
          if (this.form.cutofftime.item == null){
              this.form.cutofftime.item = "";
          }
            this.$emit('time', this.form.cutofftime.item);
        },

        'datetime.rule': function(newVal, oldVal) { // watch it
            this.form.cutofftime.rule = this.datetime.rule;
        },

        'close': function(newVal, oldVal) { // watch it
            if (newVal){
                this.form.cutofftime.item = "";
                this.form.cutofftime.rule = [];
            }
        },
    },

    computed: {
        validateCutoff(){
            let classes = '';
            if (this.focuscutoff || this.form.cutofftime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.cutofftime.item == '' && this.form.cutofftime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateCutoffMain(){
            let classes = '';
            if (this.focuscutoff){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.cutofftime.item == '' && this.form.cutofftime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            if (this.readonlylocal){
                classes += ' v-input--is-disabled';
            }

            return classes;
        },
    },

    mounted(){
        for (const [key, value] of Object.entries(this.datetime)) {
            if (key !== "rule"){
                this.form.cutofftime.item = value;
            }
        }

        this.readonlylocal = this.readonly == true ? true : false
    }
}
</script>