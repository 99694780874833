<template>
   <vc-date-picker v-model="form.datetime.item" popover :mode="mode" locale="en-GB">
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
         <v-row dense>
            <v-col cols="12" sm="12" md="12">
               <div
                  v-bind:class="[ validateStartDateMain, readonly ? 'v-input--is-disabled' : '' ]"
                  class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
                  <div class="v-input__control">
                     <div class="v-input__slot white">
                        <fieldset aria-hidden="true">
                           <legend :style="focused || form.datetime.item ? {'width': width} : {'width': '0px'}"><span>&#8203;</span></legend>
                        </fieldset>
                        <div class="v-text-field__slot">
                           <label for="input-endusedate" v-bind:class="[ validateStartDate, readonly ? 'v-label--is-disabled' : '' ]" class="v-label theme--light" style="left: 0px; right: auto; position: absolute;">{{label}}</label>
                           <input
                           id="input-endusedate"
                           @focus="focused = true"
                           @blur="focused = false"
                           :value="inputValue"
                           v-on="inputEvents"
                           @click="togglePopover({ placement: placement, visibility: 'click' })"
                           autocomplete="off"
                           :disabled="readonly"
                           />
                        </div>
                     </div>
                     <div class="v-text-field__details">
                        <v-input
                           error
                           readonly
                           v-model="form.datetime.item"
                           :rules="form.datetime.rule"
                           height="0"
                           class="hide-input-text"
                           >
                        </v-input>
                     </div>
                  </div>
               </div>
            </v-col>
         </v-row>
      </template>
   </vc-date-picker>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import * as moment from "moment";

export default {
    props: ['datetime', 'rules', 'label', 'close', 'useforedit', 'width', 'mode', 'place', 'readonly'],

    data() {
        return {
            form: {
                datetime: {item: "", formatted: "", rule: []},
            },
            focused: false,
            placement: '',
        }
    },

    components: {VueTimepicker},

    watch: { 
      	'form.datetime.item': function(newVal, oldVal) { // watch it
          if (this.form.datetime.item == null){
              this.form.datetime.item = "";
          }
            this.$emit('date', this.form.datetime.item);
        },

        'datetime.rule': function(newVal, oldVal) { // watch it
            this.form.datetime.rule = this.datetime.rule;
        },

        'close': function(newVal, oldVal) { // watch it
            if (newVal){
                this.form.datetime.item = "";
                this.form.datetime.rule = [];
            }
        },
    },

    computed: {
        validateStartDate(){
            let classes = '';
            if (this.focused || this.form.datetime.item){
                classes += 'primary--text v-label--active';
            }

            if (this.form.datetime.item == '' && this.form.datetime.rule.length > 0){
                classes += ' error--text';
            }

            return classes;
        },

        validateStartDateMain(){
            let classes = '';
            if (this.focused){
                classes += 'v-input--is-focused primary--text';
            }

            if (this.form.datetime.item == '' && this.form.datetime.rule.length > 0){
                classes += ' v-input--has-state error--text';
            }

            return classes;
        },

        errorTrigger(){
            let classes = false;
            if (this.form.datetime.item !== ''){
                classes = false;
            }

            if (this.form.datetime.item == ''){
                classes = true;
            }

            return classes;
        }
    },

    mounted(){
        this.placement = this.place == true ? "bottom-start" : "top-start";
        this.form.datetime.item = this.datetime.item;
    }
}
</script>